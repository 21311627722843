import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./MainPage.css";

function MainPage() {
  const images = [
    "https://ruihuang17.github.io/website-image/1.jpg",
    "https://ruihuang17.github.io/website-image/2.jpg",
    "https://ruihuang17.github.io/website-image/3.jpg",
    "https://ruihuang17.github.io/website-image/4.jpg",
  ];

  return (
    <div>
      <div className="image-container">
        <Fade>
          <div className="image-slide">
            <div>
              <img src={images[0]} alt="" />
            </div>
          </div>
          <div className="image-slide">
            <div>
              <img src={images[1]} alt="" />
            </div>
          </div>
          <div className="image-slide">
            <div>
              <img src={images[2]} alt="" />
            </div>
          </div>
          <div className="image-slide">
            <div>
              <img src={images[3]} alt="" />
            </div>
          </div>
        </Fade>
      </div>
      <div className="spacer" />
    </div>
  );
}

export default MainPage;
