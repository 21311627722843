import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

import NavigationBar from "./components/NavigationBar";
import MainPage from "./pages/MainPage";
import PhotographyPage from "./pages/Photography";
import VideographyPage from "./pages/Videography";

function App() {
  return (
    <div>
      <Helmet>
      <title>{'Rui Huang'}</title>
      </Helmet>
      <BrowserRouter>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/photography" element={<PhotographyPage />} />
          <Route path="/videography" element={<VideographyPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
