export const photos = [
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/2.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/3.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/4.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/5.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/6.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/7.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/8.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/9.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/10.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/11.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/12.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/13.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/14.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/15.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/16.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/17.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/18.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/19.jpg",
      width: 3,
      height: 2
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/20.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/21.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/22.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/23.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/24.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/25.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/26.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/27.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/28.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/29.jpg",
      width: 5,
      height: 4
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/30.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/31.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/32.jpg",
      width: 2,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/33.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/34.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/35.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/36.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://ruihuang17.github.io/website-image/Gallery/37.jpg",
      width: 4,
      height: 3
    }
  ];
  